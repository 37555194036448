import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ProfileImage from "../ProfileImage/ProfileImage";
import './AvatarCellRenderer.scss';

interface AvatarCellRendererProps {
    profileIdField:string;
    displayName:string;
  }
  
  const AvatarCellRenderer: FC<AvatarCellRendererProps> = ({
    profileIdField,
    displayName
  }) => {
    return profileIdField && displayName ?(
           <div className="avatar-cell-renderer">
            <ProfileImage profileId={profileIdField}></ProfileImage>
            <span>{displayName}</span>
           </div>
    ):<></>
  }

  export default AvatarCellRenderer;