import React from "react";
import { RootState } from "common/redux/core/root.reducer";
import { useSelector } from "react-redux";
import Chat from "../Chat/Chat";
import UsersList from "../UsersList/UsersList";

import "./ChatSession.scss";

const ChatSession = () => {
  const selectedConversation = useSelector((state: RootState) => state.agents.selectedConversation);

  return (
      <div className="chat-session">
        <div className="chat-session__container">
          <UsersList />
          {selectedConversation &&(<Chat />)}
          
        </div>
      </div>
  );
};

export default ChatSession;
