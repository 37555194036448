import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from './configure-store';
import agentsReducer from 'modules/gpo-agent/redux/agents';
import userReducer from '../store/user';
import agentsChatHistoryReducer from 'modules/gpo-agent/redux/agent-chat-history';
import appHeaderReducer from 'modules/gpo-agent/redux/header';
import userManagementReducer from '../store/user-management';

const rootReducer = combineReducers({
    user: userReducer,
    agents: agentsReducer,
    agentChatHistory : agentsChatHistoryReducer,
    header : appHeaderReducer,
    userManagement: userManagementReducer
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
