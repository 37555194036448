import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResult } from "common/models";
import { IACSContext } from "modules/gpo-agent/models/agents/acs-context.model";
import { IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";
import { ISession } from "modules/gpo-agent/models/agents/session.model";
import { WebPubSubDetails } from "modules/gpo-agent/models/agents/web-pub-sub-details.model";
import { put, takeLatest } from "redux-saga/effects";
import { AgentsService } from "./agents.service";
import { agentsActions } from "./agents.slice";

const getSession = function* () {
    const { hasErrors, errors, value }: ApiResult<ISession> =
        yield AgentsService.getSession();

    if (hasErrors) {
        yield put({
            type: agentsActions.getSessionFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.getSessionSuccess.type,
            payload: value
        });
    }
};

const getSessionWatch = function* () {
    yield takeLatest(agentsActions.getSession.type, getSession);
};

const getChatRequests = function* () {
    const { hasErrors, errors, value }: ApiResult<IChatRequest[]> =
        yield AgentsService.getChatRequests();

    if (hasErrors) {
        yield put({
            type: agentsActions.getChatRequestsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.getChatRequestsSuccess.type,
            payload: value
        });
    }
};

const getChatRequestsWatch = function* () {
    yield takeLatest(agentsActions.getChatRequests.type, getChatRequests);
};

const acceptConversation = function* ({ payload }: PayloadAction<string>) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsService.acceptConversation(payload);
    if (hasErrors) {
        yield put({
            type: agentsActions.acceptConversationFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.acceptConversationSuccess.type,
            payload: true
        });
    }
};

const acceptConversationWatch = function* () {
    yield takeLatest(agentsActions.acceptConversation.type, acceptConversation);
}

const getACSContext = function* () {
    const { hasErrors, errors, value }: ApiResult<IACSContext> =
        yield AgentsService.getACSContext();
    if (hasErrors) {
        yield put({
            type: agentsActions.getACSContextFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.getACSContextSuccess.type,
            payload: value
        });
    }
}

const getACSContextWatch = function* () {
    yield takeLatest(agentsActions.getACSContext.type, getACSContext);
}

const leaveConversation = function* ({ payload }: PayloadAction<string>) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsService.leaveConversation(payload);
    if (hasErrors) {
        yield put({
            type: agentsActions.leaveConversationFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.leaveConversationSuccess.type,
            payload: value
        });
    }
}

const leaveConversationWatch = function* () {
    yield takeLatest(agentsActions.leaveConversation.type, leaveConversation);
}

const getSilentChatRequest = function* () {
    const { hasErrors, errors, value }: ApiResult<IChatRequest[]> =
        yield AgentsService.getChatRequests();

    if (hasErrors) {
        yield put({
            type: agentsActions.getSilentChatRequestFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.getSilentChatRequestSuccess.type,
            payload: value
        });
    }
};

const getSilentChatRequestWatch = function* () {
    yield takeLatest(agentsActions.getSilentChatRequest.type, getSilentChatRequest);
};

const rejectConversation = function* ({ payload }: PayloadAction<string>) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsService.leaveConversation(payload);
    if (hasErrors) {
        yield put({
            type: agentsActions.rejectConversationFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.rejectConversationSuccess.type,
            payload: value
        });
    }
}

const rejectConversationWatch = function* () {
    yield takeLatest(agentsActions.rejectConversation.type, rejectConversation);
}

const webPubSubNegotiate = function* () {
    const { hasErrors, errors, value }: ApiResult<WebPubSubDetails> =
        yield AgentsService.webPubSubNegotiate();
    if (hasErrors) {
        yield put({
            type: agentsActions.webPubSubNegotiateFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.webPubSubNegotiateSuccess.type,
            payload: value
        });
    }
}

const webPubSubNegotiateWatch = function* () {
    yield takeLatest(agentsActions.webPubSubNegotiate.type, webPubSubNegotiate);
}

export {
    getSessionWatch,
    getChatRequestsWatch,
    acceptConversationWatch,
    getACSContextWatch,
    leaveConversationWatch,
    getSilentChatRequestWatch,
    rejectConversationWatch,
    webPubSubNegotiateWatch,
};
