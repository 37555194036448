import { AxiosError } from 'axios';
import { msalInstance, protectedResources } from 'common/config/auth.config';
import { HTTP_HEADER_KEY } from 'common/enums';
import { ApiResult, IGraphUser, IGraphUserLean } from 'common/models';

import { BaseHTTPClient } from './base-http-client.service';

export class GraphClient extends BaseHTTPClient {
    private static async getAccessToken(): Promise<string> {
        const acquireTokenResult = await msalInstance.acquireTokenSilent({
            scopes: protectedResources.graph.scopes
        });
        return acquireTokenResult.accessToken;
    }

    static async getCurrentUserProfile(): Promise<ApiResult<IGraphUser>> {
        try {
            const accessToken = await GraphClient.getAccessToken();
            const header = {
                [HTTP_HEADER_KEY.Authorization]: `Bearer ${accessToken}`
            };

            const url = `${protectedResources.graph.endpoint}/me`;
            return await this.get<any>(url, header);
        } catch (e) {
            const axiosError: AxiosError = e as AxiosError;;
            return new ApiResult(
                axiosError.response?.data,
                axiosError.response?.status,
                axiosError.message
            );
        }
    }

    static async getCurrentUserAvatar(): Promise<ApiResult<Blob>> {
        try {
            const accessToken = await GraphClient.getAccessToken();
            const header = {
                [HTTP_HEADER_KEY.Authorization]: `Bearer ${accessToken}`
            };

            const url = `${protectedResources.graph.endpoint}/me/photos/48x48/$value`;
            return await this.getBlob(url, header);
        } catch (e) {
            const axiosError: AxiosError = e as AxiosError;;
            return new ApiResult(
                axiosError.response?.data,
                axiosError.response?.status,
                axiosError.message
            );
        }
    }

    static async getUserAvatar(id: string): Promise<ApiResult<Blob>> {
        try {
            const accessToken = await GraphClient.getAccessToken();
            const header = {
                [HTTP_HEADER_KEY.Authorization]: `Bearer ${accessToken}`
            };

            const url = `${protectedResources.graph.endpoint}/users/${id}/photos/48x48/$value`;
            return await this.getBlob(url, header);
        } catch (e) {
            const axiosError: AxiosError = e as AxiosError;
            return new ApiResult(
                axiosError.response?.data,
                axiosError.response?.status,
                axiosError.message
            );
        }
    }

    static async searchUsers(key: string): Promise<ApiResult<IGraphUserLean[]>> {
        try {
            const accessToken = await GraphClient.getAccessToken();
            const header = {
                [HTTP_HEADER_KEY.Authorization]: `Bearer ${accessToken}`
            };
            const query = `$select=id,displayName,mail&$filter=startswith(displayName,'${key}') or startswith(mail,'${key}')&$top=30 `;
            const url = `${protectedResources.graph.endpoint}/users?${query}`;
            const result = await this.get<any>(url, header);
            return result.value.value;
        } catch (e) {
            const axiosError: AxiosError = e as AxiosError;
            return new ApiResult(
                axiosError.response?.data,
                axiosError.response?.status,
                axiosError.message
            );
        }
    }
}
