import React, { FC, useEffect, useState } from "react";
import { accept, close_red } from "assets/media/images";
import { IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { useDispatch, useSelector } from "react-redux";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import { RootState } from "common/redux/core/root.reducer";
import { ApiStatus } from "common/enums";

import "./IncommingChat.scss";

export interface IncommingChatProps {
  request: IChatRequest;
}

const IncommingChat: FC<IncommingChatProps> = ({ request }: IncommingChatProps) => {

  const dispatch = useDispatch();

  const acceptConversation = useSelector((state: RootState) => state.agents.acceptConversation.status);
  const rejectConversation = useSelector((state: RootState) => state.agents.rejectConversation.status);
  const [message, setMessage] = useState('Incoming chat');
  const [disable, setDisable] = useState(false);

  useEffect(() => {
   if (acceptConversation === ApiStatus.ERROR){
      setDisable(false);
      setMessage('Incoming chat');
    }
  }, [acceptConversation]);

  useEffect(() => {
    if (rejectConversation === ApiStatus.SUCCESS) {
      dispatch(agentsActions?.getChatRequests());
    } else  if (rejectConversation === ApiStatus.ERROR){
      setDisable(false);
      setMessage('Incoming chat');
    }
  }, [rejectConversation]);

  const trigerAccept = (event: any) => {
    if (!disable) {
      dispatch(agentsActions.acceptConversation(request?.conversationThreadId));
      setMessage('Accepting chat');
      setDisable(true);
    }
  }

  const trigerReject = (event: any) => {
    if (!disable) {
      dispatch(agentsActions.rejectConversation(request?.conversationThreadId));
      setMessage('Rejecting chat');
      setDisable(true);
    }
  }
  return (
    <div className="incomming-chat-session">
      <div className="incomming-chat-session__container">
        <header>
          <ProfileImage profileId={request?.userGuid} />
          <strong>{request?.displayName}</strong>
          <div className="calling">
            <span>{message}</span>
            <div className="status">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>
        <div className="actions">
          <span onClick={trigerReject}><img src={close_red} /></span>
          <span onClick={trigerAccept}><img src={accept} /></span>
        </div>
      </div>
    </div>
  );
};

export default IncommingChat;
