import { ApiStatus } from "common/enums";
import { IAsyncData } from "common/models/store/store.model";
import { IACSContext } from "modules/gpo-agent/models/agents/acs-context.model";
import { IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";
import { ISession } from "modules/gpo-agent/models/agents/session.model";
import { WebPubSubDetails } from "modules/gpo-agent/models/agents/web-pub-sub-details.model";

interface IAgentsState {
    session: IAsyncData<ISession>;
    chatRequest: IAsyncData<IChatRequest[]>;
    acceptConversation: IAsyncData<boolean>;
    selectedConversation?: IChatRequest;
    aCSContext: IAsyncData<IACSContext>;
    leaveConversation: IAsyncData<boolean>;
    chatRequestSilent: IAsyncData<IChatRequest[]>;
    rejectConversation: IAsyncData<boolean>;
    webPubSubNegotiateDetails: IAsyncData<WebPubSubDetails>;
}

const initalAgentsState: IAgentsState = {
    session: {
        status: ApiStatus.LOADING
    },
    chatRequest: {},
    acceptConversation: {},
    aCSContext: {},
    leaveConversation: {},
    chatRequestSilent:{},
    rejectConversation: {},
    webPubSubNegotiateDetails:{}
};

export { initalAgentsState };
