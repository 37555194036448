import { FC, useCallback, useEffect, useMemo, useState } from "react";
import './ArrayofDataCellRenderer.scss';

interface ArrayofDataCellRendererProps {
    arrayofData:Array<any>;
    fieldToShow:string;
  }
  
  const ArrayofDataCellRenderer: FC<ArrayofDataCellRendererProps> = ({
    arrayofData=[],
    fieldToShow
  }) => {
    const [wrapperData, setWrapperData] = useState<string>("");

    useEffect(()=>{
        if(arrayofData?.length)
        setWrapperData(arrayofData.map((value) => value[fieldToShow]).join(', '));   
    },[arrayofData])
    return(
        <div className="array-of-data-cell-renderer">
              {wrapperData} 
        </div>
    )
  }

  export default ArrayofDataCellRenderer;