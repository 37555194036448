import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { HandOffStatusEnum, IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import { accept_white, close_white } from "assets/media";
import { RootState } from "common/redux/core/root.reducer";
import { ApiStatus } from "common/enums";

export interface UsersListItemProps {
    item: IChatRequest
    itemKey: number
}

const UsersListItem: FC<UsersListItemProps> = ({ item, itemKey }: UsersListItemProps) => {
    const dispatch = useDispatch();
    const acceptConversation = useSelector((state: RootState) => state.agents.acceptConversation.status);
    const rejectConversation = useSelector((state: RootState) => state.agents.rejectConversation.status);
    const chatRequestSilentStatus = useSelector((state: RootState) => state.agents.chatRequestSilent.status);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (acceptConversation === ApiStatus.SUCCESS && showLoader === true)
            dispatch(agentsActions.getSilentChatRequest());
    }, [acceptConversation]);

    useEffect(() => {
        if (rejectConversation === ApiStatus.SUCCESS && showLoader === true)
            dispatch(agentsActions.getSilentChatRequest());
    }, [rejectConversation]);

    useEffect(() => {
        if (chatRequestSilentStatus === ApiStatus.SUCCESS && showLoader === true)
            setShowLoader(false);
    }, [chatRequestSilentStatus]);
    
    const accept = (item: IChatRequest) => {
        dispatch(agentsActions.acceptConversation(item?.conversationThreadId));
        setShowLoader(true);
    }

    const reject = (item: IChatRequest) => {
        dispatch(agentsActions.rejectConversation(item?.conversationThreadId));
        setShowLoader(true);
    }
    return (
        <>
            {showLoader === false && (
                <div className={item?.handOffStatusId === HandOffStatusEnum.Accepted ? "lists selected" : "lists"} key={itemKey}>
                    <div className="left">
                        <ProfileImage profileId={item?.userGuid} />
                        <div className="left__action">
                            <strong>
                                {item?.displayName}
                            </strong>
                            <span>
                                {item?.emailId}
                            </span>
                        </div>
                    </div>
                    <div className="right">
                        {item?.canAccept === true && (
                            <>
                                <span onClick={() => { reject(item) }}>
                                    <img src={close_white} />
                                </span>
                                <span onClick={() => { accept(item) }}>
                                    <img src={accept_white} />
                                </span>
                            </>
                        )}
                        {(item?.canAccept === false && item?.handOffStatusId === HandOffStatusEnum.Queued) && (
                            <div className="queued">
                                In queue...
                            </div>
                        )}
                    </div>
                </div>
            )}
            {showLoader === true && (
                <div className="card" key={itemKey}>
                    <div className="header">
                        <div className="img"></div>
                        <div className="details">
                            <span className="name"></span>
                            <span className="about"></span>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default UsersListItem;