import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "common/redux/core/root.reducer";
import { ApiStatus } from "common/enums";
import UsersListItem from "../UsersListItem/UsersListItem";
import { IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";

import "./UsersList.scss";

const UsersList = () => {

  const chatRequestStatus = useSelector((state: RootState) => state.agents.chatRequest.status);
  const chatRequestList = useSelector((state: RootState) => state.agents.chatRequest.data);
  const chatRequestSilent = useSelector((state: RootState) => state.agents.chatRequestSilent.data);
  const [requestList, setRequestList] = useState<IChatRequest[]>([]);

  useEffect(() => {
    if(chatRequestList && chatRequestList?.length > 0){
      setRequestList(chatRequestList);
    }
  },[chatRequestList]);

  useEffect(() => {
    if(chatRequestSilent && chatRequestSilent?.length > 0){
      setRequestList(chatRequestSilent);
    }
  },[chatRequestSilent]);
  
  return (
    <div className="users-list">
      <div className="users-list__container">
        {chatRequestStatus === ApiStatus.SUCCESS && (
          <>
            {requestList?.map((item, i) => (
              <UsersListItem item={item} itemKey={i} key={i}/>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default UsersList;
