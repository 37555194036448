import { DateFormatterService } from "common/services/grid-date-formatter.service";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import './GridDateCellRenderer.scss';

interface GridDateCellRendererProps {
    dateToConvert:string;
    format:string;
  }
  
  const GridDateCellRenderer: FC<GridDateCellRendererProps> = ({
    dateToConvert,
    format
  }) => {
    const [formattedDate, setFormattedDate] = useState<string>("");

    useEffect(()=>{
        if(dateToConvert && format){
            setFormattedDate(DateFormatterService.covertUTCDateToLocal(dateToConvert,format));
        }
         
    },[dateToConvert,format])
    return(
        <div className="array-of-data-cell-renderer">
              {formattedDate} 
        </div>
    )
  }

  export default GridDateCellRenderer;