import { StatusCellRendererMapper } from "common/models/status-cell-renderer-mapper.model";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ProfileImage from "../ProfileImage/ProfileImage";
import './StatusCellRenderer.scss';

interface StatusCellRendererProps {
    statusMapper:Array<StatusCellRendererMapper>;
    statusValue:any;
  }
  
  const StatusCellRenderer: FC<StatusCellRendererProps> = ({
    statusMapper,
    statusValue
  }) => {
    const [currentStatus, setCurrentStatus] = useState<StatusCellRendererMapper>();
    useEffect(()=>{
        if(statusMapper){
            let selectedStatus = statusMapper.filter((status:StatusCellRendererMapper) => status.value == statusValue );  
            if(selectedStatus){
                setCurrentStatus(selectedStatus[0]);
            } 
        }
    },[statusValue,statusMapper])
    return(
        <div className="status-cell-renderer">
             {currentStatus &&(
                <>
                    <span className="status-cell-renderer__circle" style={{backgroundColor: currentStatus?.color}}></span>
                    <span>{currentStatus?.displayName}</span>
                </>
             )}
        </div>
    )
  }

  export default StatusCellRenderer;