import React, { useState } from "react";
import Leave from "../Leave/Leave";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { useSelector } from "react-redux";
import { RootState } from "common/redux/core/root.reducer";
import moment from "moment";

import { leave } from "assets/media/images";

import "./Header.scss";

const Header = () => {

  const selectedConversation = useSelector((state: RootState) => state.agents.selectedConversation);
  const [isLeave, setIsLeave] = useState(false);
  const toggle = (isLeave) => {
    setIsLeave((isLeave) => !isLeave);
  };
  const handleLeaveModalCancel = () => {
    setIsLeave(false);
  }
  return (
    <div className="agent-header">
      <div className="agent-header__container">
        <h2>Active Session</h2>
        {selectedConversation && (
          <div className="details">
            <div className="details__left">
              <ProfileImage profileId={selectedConversation?.userGuid} />
              <div className="details__left__action">
                <strong>{selectedConversation?.displayName}</strong>
                <span>{moment.utc(selectedConversation?.handOffStartDate).local().fromNow()}</span>
              </div>
            </div>
            <div className="details__right">
              <div className="leave" onClick={toggle}>
                <img src={leave} />
                <span>Leave</span>
              </div>
            </div>
          </div>
        )}
      </div>
      {isLeave && <Leave onModalCancel={handleLeaveModalCancel} />}
    </div>
  )
};

export default Header;
