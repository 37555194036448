import { ApiResult } from "common/models";
import { HttpClient } from "common/services/http-client.service";
import { IACSContext } from "modules/gpo-agent/models/agents/acs-context.model";
import { IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";
import { ISession } from "modules/gpo-agent/models/agents/session.model";
import { WebPubSubDetails } from "modules/gpo-agent/models/agents/web-pub-sub-details.model";
import agentsClientApiConfig from "./agents.config";

export class AgentsService extends HttpClient {
    public static async getSession(): Promise<ApiResult<ISession> | undefined> {
        return await this.invokeApi<ISession>(agentsClientApiConfig.session());
    }

    public static async getChatRequests(): Promise<ApiResult<IChatRequest[]> | undefined> {
        return await this.invokeApi<IChatRequest[]>(agentsClientApiConfig.chatRequests());
    }

    public static async acceptConversation(conversationThreadId  : string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsClientApiConfig.acceptConversation(conversationThreadId));
    }

    public static async getACSContext(): Promise<ApiResult<IACSContext> | undefined> {
        return await this.invokeApi<IACSContext>(agentsClientApiConfig.getACSContext());
    }

    public static async leaveConversation(conversationThreadId: string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsClientApiConfig.leaveConversation(conversationThreadId));
    }

    public static async webPubSubNegotiate(): Promise<ApiResult<WebPubSubDetails> | undefined> {
        return await this.invokeApi<WebPubSubDetails>(agentsClientApiConfig.webPubSubNegotiate());
    } 
}
