import "./SkeletonLoader.scss";

const SkeletonLoader = () => {
  return (
    <div className="skelton-loader">
      <div className="skelton-loader__container">
        <h1>
          <span></span>
        </h1>
        <div className="card">
          <div className="header">
            <div className="img"></div>
            <div className="details">
              <span className="name"></span>
              <span className="about"></span>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="header">
            <div className="img"></div>
            <div className="details">
              <span className="name"></span>
              <span className="about"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
