import React, { FC, useEffect, useRef, useState } from "react";
import {IFilterNameValues, IFilterValues} from "modules/gpo-agent/models/filter-component/filter-values.model";
import "./Filter.scss";
import { filter } from "assets/media";

type FilterProps = {
  filterValues:Array<IFilterNameValues>
  onFilterApplied: (filterMap:any) => void;
  onCloseModal: () => void;
  currentSelectedFilter?:any;
  onResetFilter :() =>void;
};
const Filter : FC<FilterProps> = ({
  filterValues,
  onFilterApplied,
  onCloseModal,
  currentSelectedFilter={},
  onResetFilter
}: FilterProps) => {
  const filterRef:any = useRef(null);
  const [filterMap,setFilterMap] = useState<any>({});
  const [valueChanged,setValueChanged] = useState<boolean>(false);
    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
          onCloseModal();
        }
    };
  useEffect(()=>{
    setFilterMap(currentSelectedFilter);
  },[currentSelectedFilter])

    const handleFilterSelection = (filterCode:string,valuesCode:string,checked:boolean) =>{
      setValueChanged(true);
      let filterMapValues = filterMap;
      if(filterMapValues[filterCode]){
        let values = filterMapValues[filterCode];
        if(checked){
          values.push(valuesCode);
        }
        else{
          const index = values.indexOf(valuesCode);
          if (index > -1) { 
            values.splice(index, 1);
          }
        }
        
      }
      else{
        if(checked){
          filterMapValues[filterCode] = [valuesCode];
        }
      }
      setFilterMap(filterMapValues);
    }

    const applyFilter = () =>{
      onFilterApplied(filterMap);
    }

    const resetFilter = () =>{
      onResetFilter();
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
   return (
    <div className="filter-wrapper" ref={filterRef}>
      <div className="top">
        <h5>Filter</h5>
        {filterValues?.map((filterData, i) => 
           <div className="filter-wrapper__options" key={filterData?.filterCode}>
           <h5>{filterData?.filterName}</h5>
           {filterData?.filterValues?.map((values, i)=>
              <div className="list" key={values?.code}>
              <input type="checkbox" defaultChecked={currentSelectedFilter && currentSelectedFilter[filterData?.filterCode] && currentSelectedFilter[filterData?.filterCode]?.indexOf(values?.code)!=-1} id={values?.code} onChange={(e) => handleFilterSelection(filterData?.filterCode,values?.code,e.target.checked)}/>
              <label htmlFor={values?.code}>
                <span></span> {values?.value}
              </label>
            </div>
           )}
          </div>
        )}
        </div>
      <footer>
        <button className="btn btn-primary" disabled={!valueChanged} onClick={(e) => applyFilter()}>Apply</button>
        <button className="btn btn-secondary "  onClick={(e) => resetFilter()}>Reset</button>
      </footer>
    </div>
  );
};
export default Filter;
