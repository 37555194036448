import { ApiConfig, ApiResult } from "common/models";
import { HttpClient } from "./http-client.service";

export class GridService extends HttpClient {
    public static async getTableData(url:any): Promise<ApiResult<any> | undefined> {
        const apiConfig = new ApiConfig(`${url}`, 'GET');
    
        return await this.invokeApi<any>(apiConfig);
    }
    public static async getServerSidePaginationData(urlInfo:string,currentPageNumber:number,pageSize:number,sortOrder?:number,sortColumn?:string,filterstring?:string,searchFilter?:string,additionalQueryString?:string):Promise<ApiResult<any> | undefined> {
        let queryString = `PageNumber=${currentPageNumber}&PageSize=${pageSize}`;
        if(sortColumn&&sortOrder){
            queryString =  queryString+`&SortOrder=${sortOrder}&SortColumn=${sortColumn}`;
        }
        if(filterstring){
            queryString = queryString+filterstring;
        }
        if(searchFilter){
            queryString =  queryString+`&Filters=${searchFilter}`;
        }
        if(additionalQueryString){
            queryString =  queryString+`${additionalQueryString}`;
        }
        let apiConfig :ApiConfig<any> = {
            url: urlInfo,
            method:'GET',
            queryString: encodeURIComponent(queryString),
        }
        return await this.invokeApi<any>(apiConfig);
    }
}