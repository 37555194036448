import { CONTROLLER, METHOD, WEB_PUB_SUB_CONFIG } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";

const agentsClientApiConfig = {

    session: (): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.SESSION}`, 'POST', '', {}),
    chatRequests: (): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT_REQUESTS}`, 'GET', '', {}),
    acceptConversation: (conversationThreadId: string): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT}/${conversationThreadId}/${METHOD.ACCEPT}`, 'POST', '', {}),
    getACSContext: (): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CONTEXT}`, 'GET', '', {}),
    leaveConversation: (conversationThreadId: string): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT}/${conversationThreadId}/${METHOD.LEAVE}`, 'POST', '', {}),
    webPubSubNegotiate: (): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.WEB_PUB_SUB}/${WEB_PUB_SUB_CONFIG.HUB_NAME}/${METHOD.NEGOTIATE}`, 'GET', '', {}),
};
export default agentsClientApiConfig;