import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import { userActions } from 'common/redux/store/user';
import { RootState } from 'common/redux/core/root.reducer';
import { UserSigningStatus } from 'common/enums/user.enum';
import { agentsActions } from 'modules/gpo-agent/redux/agents';

const useAuthenticate = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const isAuthenticated = useIsAuthenticated();

    const isTokenBlacklisted = useSelector((state: RootState) => state.user.isTokenBlacklisted);

    useEffect(() => {
        if (isTokenBlacklisted) {
            const logoutRequest = {
                account: account,
                onRedirectNavigate: () => {
                    return false;
                }
            };

            instance.logout(logoutRequest).then(() => {
                dispatch(userActions.setUserSigningStatus(UserSigningStatus.SIGNED_OUT));
                history.push('/');
            });
        }
    }, [isTokenBlacklisted]);

    useEffect(() => {
        if (account && isAuthenticated) {
            instance.setActiveAccount(account);
            dispatch(userActions.getUserProfile());
            dispatch(userActions.setUserSigningStatus(UserSigningStatus.SIGNED));
            dispatch(userActions.getCurrentUserAvatar());
            dispatch(agentsActions.getSession());
        }
    }, [isAuthenticated]);
};

export default useAuthenticate;
