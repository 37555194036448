import { ApiResult } from "common/models";
import { put, takeLatest } from "redux-saga/effects";
import { AgentsChatHistoryService } from "./agent-chat-history.service";
import { agentChatHistoryActions } from "./agent-chat-history.slice";

const getChatHistoryList = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsChatHistoryService.getChatHistoryList(action?.payload);

    if (hasErrors) {
        yield put({
            type: agentChatHistoryActions.getChatHistoryListFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentChatHistoryActions.getChatHistoryListSuccess.type,
            payload: value
        });
    }
};

const getChatHistoryListWatch = function* () {
    yield takeLatest(agentChatHistoryActions.getChatHistoryList.type, getChatHistoryList);
};

const getChatHistoryMessages = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsChatHistoryService.getChatHistoryMessages(action?.payload);
    if (hasErrors) {
        yield put({
            type: agentChatHistoryActions.getChatHistoryMessagesFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentChatHistoryActions.getChatHistoryMessagesSuccess.type,
            payload: value
        });
    }
};


const getChatHistoryMessagesWatch = function* () {
    yield takeLatest(agentChatHistoryActions.getChatHistoryMessages.type, getChatHistoryMessages);
};

const getReferenceDocuments = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsChatHistoryService.getReferenceDocuments(action?.payload);
    if (hasErrors) {
        yield put({
            type: agentChatHistoryActions.getReferenceDocumentFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentChatHistoryActions.getReferenceDocumentSuccess.type,
            payload: value
        });
    }
};


const getReferenceDocumentsWatch = function* () {
    yield takeLatest(agentChatHistoryActions.getReferenceDocument.type, getReferenceDocuments);
};

const completedAnnotation = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsChatHistoryService.completeAnnotation(action?.payload);
    if (hasErrors) {
        yield put({
            type: agentChatHistoryActions.completeAnnotationFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentChatHistoryActions.completeAnnotationSuccess.type,
            payload: value
        });
    }
};


const completedAnnotationWatch = function* () {
    yield takeLatest(agentChatHistoryActions.completeAnnotation.type, completedAnnotation);
};

const annotateMessage = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsChatHistoryService.annotateMessage(action?.payload);

    if (hasErrors) {
        yield put({
            type: agentChatHistoryActions.annotateMessageFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentChatHistoryActions.annotateMessageSuccess.type,
            payload: value
        });
    }
};

const annotateMessageWatch = function* () {
    yield takeLatest(agentChatHistoryActions.annotateMessage.type, annotateMessage);
};


export {
    getChatHistoryListWatch,
    getChatHistoryMessagesWatch,
    getReferenceDocumentsWatch,
    completedAnnotationWatch,
    annotateMessageWatch
};
