import React, { useState, useEffect } from "react";
import ChatHistoryList from "../ChatHistoryList/ChatHistoryList";
import ChatHistoryUsersList from "../ChatHistoryUsersList/ChatHistoryUsersList";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { RootState, useAppSelector } from "common/redux/core/root.reducer";
import { useDispatch, useSelector } from "react-redux";
import { ApiStatus } from "common/enums";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { TabType } from "common/enums/tab-types.enum";

import "./ChatHistory.scss";

const ChatHistory = () => {

  const dispatch = useDispatch();
  const chatHistoryState = useAppSelector((state: RootState) => state.agentChatHistory);
  const acceptConversation = useSelector((state: RootState) => state.agents.acceptConversation.status);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (acceptConversation === ApiStatus.SUCCESS) {
      dispatch(appHeaderActions?.updateTabType(TabType.ACTIVE_SESSION));
    }
  }, [acceptConversation]);

  useEffect(() => {
    if (!chatHistoryState?.chatHistoryList && !chatHistoryState?.searchText && !chatHistoryState?.searchTextChanged) {
      setLoading(true);
    }
    else {
      setLoading(false);
    }
  }, [chatHistoryState?.chatHistoryList]);

  return (
    <div className="chat-history">
      <div className="chat-history__container">
        {!!loading && <SkeletonLoader />}
        <ChatHistoryUsersList />
        <ChatHistoryList />
      </div>

    </div>
  );
};

export default ChatHistory;
