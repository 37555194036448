import { all } from 'redux-saga/effects';
import { acceptConversationWatch, getACSContextWatch, getSilentChatRequestWatch, getChatRequestsWatch, getSessionWatch, leaveConversationWatch, rejectConversationWatch, webPubSubNegotiateWatch } from 'modules/gpo-agent/redux/agents/agents.saga';
import { getCurrentUserAvatarWatch, getUserProfileWatch,getUserAvatarWatch, requestAccessWatch } from '../store/user/user.saga';
import { completedAnnotationWatch, getChatHistoryListWatch,getChatHistoryMessagesWatch, getReferenceDocumentsWatch, annotateMessageWatch } from 'modules/gpo-agent/redux/agent-chat-history/agent-chat-history.saga';
import { addNewUsersWatch, approveRejectUserWatch, getAllGraphUsersWatch } from '../store/user-management/user-management.saga';

export default function* rootSaga() {
    yield all([
        getUserProfileWatch(),
        getCurrentUserAvatarWatch(),
        getSessionWatch(),
        getChatHistoryListWatch(),
        getChatHistoryMessagesWatch(),
        getUserAvatarWatch(),
        getSessionWatch(),
        getChatRequestsWatch(),
        acceptConversationWatch(),
        getACSContextWatch(),
        getReferenceDocumentsWatch(),
        completedAnnotationWatch(),
        annotateMessageWatch(),
        leaveConversationWatch(),
        getSilentChatRequestWatch(),
        rejectConversationWatch(),
        webPubSubNegotiateWatch(),
        getAllGraphUsersWatch(),
        addNewUsersWatch(),
        approveRejectUserWatch(),
        requestAccessWatch()
    ]);
}
