import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TabType } from 'common/enums/tab-types.enum';
import { initialAppHeaderState } from './header.state';


const appHeaderSlice = createSlice({
    name: 'appHeader',
    initialState: initialAppHeaderState,
    reducers: {
        updateTabType: (state, { payload }: PayloadAction<TabType>) => {
            state.tabType = payload;
        },
        updateNavBarTabChangeState : (state, { payload }: PayloadAction<boolean>)=>{
            state.isNavBarTabChange = payload;
        }
    }
});

export const appHeaderActions = appHeaderSlice.actions;

export default appHeaderSlice.reducer;
