import { close_white, down, pdf, tick } from "assets/media/images";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { DocumentTypes } from "modules/gpo-agent/enums/document-types.enum";
import { AnnotateMessageDocumentPage, AnnotateMessageQuestion } from "modules/gpo-agent/models/chat-history/chat-history-annotate-message.model";
import { IDocumentDetails, IReferenceDocumentsResponse } from "modules/gpo-agent/models/chat-history/reference-documents-response.model";
import React, { FC, useEffect, useState } from "react";
import { agentChatHistoryActions } from '../../redux/agent-chat-history/agent-chat-history.slice';
import "./AnnotateDetails.scss";

interface AnnotateDetailsProps {
  onAnnotateDetailsClose: () => void;
  messageGuid:string|undefined;
  handOffGuid:string|undefined;
  userGuid:string|undefined
}

const AnnotateDetails: FC<AnnotateDetailsProps> = ({
  onAnnotateDetailsClose,
  messageGuid,
  handOffGuid,
  userGuid
}) => {
  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState("");
  const handleOpen = () => {
    setOpen(!open);
  };
  const chatHistoryDispatch = useAppDispatch();
  const chatHistoryState = useAppSelector((state: RootState) => state.agentChatHistory);
  const [dropdownValue, setDropdownValue] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState<IDocumentDetails[]>();
  const [selectedDocument, setSelectedDocument] = useState("");
  const [pages, setPages] = useState("");
  

  useEffect(()=>{
    chatHistoryDispatch(agentChatHistoryActions?.getReferenceDocument(DocumentTypes.REFERENCE_DOCUMENT));
  },[])

  useEffect(()=>{
    const referenceDocumentsResponse:IReferenceDocumentsResponse|undefined = chatHistoryState?.referenceDocuments;
    if(referenceDocumentsResponse?.isSuccess){
      const referenceDocuments:IDocumentDetails[] = referenceDocumentsResponse?.annotateDocumentDetails;
      if(referenceDocuments){
         setDropdownOptions(referenceDocuments);
      }
    }
  },[chatHistoryState?.referenceDocuments])

  const handleChange = (value,key) => {
    setDropdownValue(value);
    setSelectedDocument(key)
    setOpen(false);
  };

  const annotateMessage = () =>{
    if(messageGuid && handOffGuid && userGuid){
      const documentPages :AnnotateMessageDocumentPage[]  = pages?.split(",")?.map(page => ({ pageNumber:parseInt(page?.trim())}));
      const annotateMessageAnswer = 
      {
          answerMessageGuid: messageGuid,
          score: 100,
          documents: [
            {
              documentGuid: selectedDocument,
              pages: documentPages
            }
          ]
      }
      const annotateMessageQuestion:AnnotateMessageQuestion[] = questions?.split(/\n/)?.map(question => ({ questionText: question }));
      const annotateMessageDetails ={
         handOffGuid: handOffGuid,
         userGuid: userGuid,
         questions: annotateMessageQuestion,
         answer: annotateMessageAnswer
      }
      chatHistoryDispatch(agentChatHistoryActions?.annotateMessage({handoffGuid:handOffGuid,messageGuid:messageGuid,annotateMessageDetails:annotateMessageDetails}));
    }
  }
  return (
    <div className="annotate-details">
      <div className="annotate-details__container">
        <div className="row">
          <span>Type all possible question of this anwer</span>
          <textarea onChange={(e) => setQuestions(e?.target?.value)}></textarea>
        </div>
        <div className="row">
          <span>Select the source document</span>
          <div className="dropdown">
            <button onClick={handleOpen}>
              {dropdownValue && (
               <img className="pdf" src={pdf} />
              )}
              {dropdownValue}
              {!dropdownValue && (
                <span>Select a document</span>
              )}  
              <img src={down} className={open ? "active arrow" : "arrow"} />
            </button>
            {open ? (
              <ul>
                {dropdownOptions?.map((item,index) => (
                  <li onClick={() => handleChange(item?.documentName,item?.documentGuid)} key={index}>
                    <img src={pdf} /> {item?.documentName}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
        <div className="row">
          <span>Select the page numbers</span>
          <input type="text" onChange={(e) => setPages(e?.target?.value)} />
        </div>
        <footer>
          <img src={close_white} onClick={() => onAnnotateDetailsClose()}/>
          <img src={tick} onClick={(event) => annotateMessage()}/>
        </footer>
      </div>
    </div>
  );
};

export default AnnotateDetails;
