import { alerts, avatar, search } from "assets/media/images";
import React, { useState,useEffect } from "react";
import { agentChatHistoryActions } from '../../redux/agent-chat-history/agent-chat-history.slice';
import { IChatHistory, IChatHistoryList } from "modules/gpo-agent/models/chat-history/chat-history-list.model";
import "./ChatHistoryUsersList.scss";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import moment from "moment";
import { userActions } from "common/redux/store/user";
import { useSelector } from "react-redux";
import { UserService } from "common/redux/store/user/user.service";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { appHeaderActions } from "modules/gpo-agent/redux/header";

const ChatHistoryUsersList = () => {
    const chatHistoryDispatch = useAppDispatch();
    const chatHistoryState = useAppSelector((state: RootState) => state.agentChatHistory);
    const usersState = useAppSelector((state: RootState) => state.user);
    const [chatHistoryUsers, setChatHistoryUsers] = useState<Array<IChatHistory>>([]);
    const isNavBarTabChange = useSelector((state: RootState) => state.header.isNavBarTabChange); 
    const [searchText, setSearchText] = useState('')
    
    useEffect(() => {
        const chatHistoryUsersListResponse:IChatHistoryList|undefined = chatHistoryState?.chatHistoryList;
        if(!chatHistoryUsersListResponse){
          setChatHistoryUsers([]);
          chatHistoryDispatch(agentChatHistoryActions.updateSearchMessagesText());
        }
        if(chatHistoryUsersListResponse?.isSuccess){
           chatHistoryDispatch(agentChatHistoryActions.updateSearchMessagesText());
           const chatHistory = chatHistoryUsersListResponse?.chatHistory;
           if(chatHistory){
            setChatHistoryUsers(chatHistory);
            if(isNavBarTabChange){
                chatHistoryDispatch(agentChatHistoryActions.updateSelectedChatHandOffGuid(chatHistory[0]?.handoffGuid));
                chatHistoryDispatch(appHeaderActions?.updateNavBarTabChangeState(false));
            }
           }
        }
    }, [chatHistoryState?.chatHistoryList]);

    useEffect(() =>{
        if(chatHistoryState?.currentSelectedChatGuid && chatHistoryState?.chatHistoryList){
            const chatHistoryUsersListResponse:IChatHistoryList|undefined = chatHistoryState?.chatHistoryList;
            if(chatHistoryUsersListResponse && chatHistoryUsersListResponse?.isSuccess){
                const chatHistory:IChatHistory[] = chatHistoryUsersListResponse?.chatHistory;
                const selectedChat = chatHistory?.filter((chat:IChatHistory) => chat?.handoffGuid == chatHistoryState?.currentSelectedChatGuid );
                if(selectedChat){
                    chatHistoryDispatch(agentChatHistoryActions.updateSelectedChatHistory(selectedChat[0]));
                }
            }
        }
     },[chatHistoryState?.currentSelectedChatGuid,chatHistoryState?.chatHistoryList])

     useEffect(() => {
        console.log("Search Text",searchText)
      }, [searchText])
    
    const handleSearchInputChange = (searchInput) =>{
      const delayDebounceFn = setTimeout(() => {
        console.log("on change",searchText);
        chatHistoryDispatch(agentChatHistoryActions?.clearChatHistoryList());
        chatHistoryDispatch(agentChatHistoryActions?.clearSelectedChatHistory());
        chatHistoryDispatch(agentChatHistoryActions?.clearChatHistoryMessages());
        chatHistoryDispatch(agentChatHistoryActions.updateSearchTextChanged(true));
        chatHistoryDispatch(agentChatHistoryActions.getChatHistoryList(searchInput));

        setSearchText(searchInput)
      }, 1000)
  
      return () => clearTimeout(delayDebounceFn)
    }

    const selectChat = (event:any,history:IChatHistory) =>{
        let selectedChats:any =  document.getElementsByClassName("users-lists selected");
        if(selectedChats && selectedChats?.length){
            for (let chat of selectedChats) {
                chat?.classList?.remove("selected");
            }
        }
        // let chatToSelect = event?.currentTarget;
        // if(chatToSelect){
        //     chatToSelect?.classList?.add("selected");
        // }
        chatHistoryDispatch(agentChatHistoryActions?.updateChatAnnotateView(false));
        chatHistoryDispatch(agentChatHistoryActions.updateSelectedChatHandOffGuid(history?.handoffGuid));
    }
  return (
    <div className="chat-history-users">
      <div className="chat-history-users__header">
        <h2>Chat History</h2>
      </div>
      <div className="chat-history-users__container">
        <section className="search">
          <img src={search} />
          <input type="search" 
           placeholder="Search messages..." 
           onChange={(e) => handleSearchInputChange(e.target.value)}
          />
        </section>
        <div className="users-wrapper">
        {!chatHistoryState?.chatHistoryList && chatHistoryState?.searchTextChanged &&(
        <div className="card">
          <div className="header">
            <div className="img"></div>
            <div className="details">
              <span className="name"></span>
              <span className="about"></span>
            </div>
          </div>
        </div>
        )}
        {chatHistoryUsers && chatHistoryUsers?.map((history, i) => 
          <React.Fragment key={history?.handoffGuid || i}>
            <div className={"users-lists " + (history?.handoffGuid==chatHistoryState?.currentSelectedChatGuid ? 'selected' : '')} onClick={(event) => selectChat(event,history)}>
              <div className="left">
                <ProfileImage profileId={history?.guid} />
                <div className="left__action">
                  <strong>{history?.displayName}</strong>
                  <span>{history?.messageText}</span>
                </div>
              </div>
             <div className="right">
              <span>{moment.utc(history?.messageDateTime)?.local().fromNow()}</span>
              {!history?.isAnnotated && (
                <img src={alerts} />
              )} 
           </div>
        </div>   
          </React.Fragment>
        )}
        </div>
      </div>
    </div>
  );
};

export default ChatHistoryUsersList;