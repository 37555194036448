import React from "react";
import Home from "modules/gpo-agent/pages/Home/Home";

/*logout*/
const AuthorizedLayout = () => {
    return (
        <>
            <Home />
        </>
    )
}

export default AuthorizedLayout;
