import React, { FC, useEffect, useState } from "react";
import { strengthen } from "assets/media/images";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { agentChatHistoryActions } from "modules/gpo-agent/redux/agent-chat-history";
import { useDispatch, useSelector } from "react-redux";
import { TabType } from "common/enums/tab-types.enum";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import { RootState } from "common/redux/core/root.reducer";
import { ApiStatus } from "common/enums";
import { IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";

import "./Leave.scss";

type LeaveProps = {
  onModalCancel: () => void;
};
const Leave: FC<LeaveProps> = ({
  onModalCancel: handleModalCancel,
}: LeaveProps) => {
  const dispatch = useDispatch();
  const selectedConversation = useSelector((state: RootState) => state.agents.selectedConversation);
  const leaveConversation = useSelector((state: RootState) => state.agents.leaveConversation.data);
  const leaveConversationStatus = useSelector((state: RootState) => state.agents.leaveConversation.status);
  const [strength, setStrength] = useState(false);
  const [stateSelectedConversation, setStateSelectedConversation] = useState<IChatRequest>();

  useEffect(() => {
    if (leaveConversation) {
      setStrength(true);
    }
  }, [leaveConversation])

  const moveToChatHistory = (event: any) => {
    if (stateSelectedConversation?.handOffGuid) {
      dispatch(appHeaderActions?.updateNavBarTabChangeState(false));
      dispatch(appHeaderActions?.updateTabType(TabType.CHAT_HISTORY));
      dispatch(agentChatHistoryActions?.updateChatAnnotateView(true));
      dispatch(agentChatHistoryActions?.updateSelectedChatHandOffGuid(stateSelectedConversation?.handOffGuid));
    }
  }

  const trigerLeaveConversation = (event: any) => {
    setStateSelectedConversation(selectedConversation);
    const conversationThreadId = selectedConversation?.conversationThreadId
    conversationThreadId && dispatch(agentsActions.leaveConversation(conversationThreadId));
  }

  const mayBeLater = (event: any) => {
    dispatch(agentsActions.getChatRequests());
    setStrength(false);
    handleModalCancel();
  }

  return (
    <div className="leave-popup">
      <div className="leave-popup__container">
        {!strength ? (
          <div className="leave-wrapper">
            <strong>Leave the conversation?</strong>
            <p>You will have access to the chat history</p>
            <footer>
              <button className="cancel" onClick={handleModalCancel} disabled={leaveConversationStatus === ApiStatus.LOADING}>
                Cancel
              </button>
              <button
                className="yes"
                onClick={trigerLeaveConversation}
                disabled={leaveConversationStatus === ApiStatus.LOADING}
              >
                Leave
                {leaveConversationStatus === ApiStatus.LOADING && (<span className="dots-circle-spinner loading"></span>)}
              </button>
            </footer>
          </div>
        ) : <div className="strength">
          <div className="strength__container">
            <img src={strengthen} />
            <strong>Strengthening AI</strong>
            <p>Do you have a few minutes to spare for improving the AI</p>
            <footer>
              <button className="cancel" onClick={mayBeLater}>
                May be later
              </button>
              <button
                className="yes"
                onClick={moveToChatHistory}
              >
                Yes
              </button>
            </footer>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default Leave;
