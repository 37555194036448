import React, { useEffect } from "react";
import { isolation } from "assets/media/images";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "common/redux/core/root.reducer";
import { ApiStatus } from "common/enums";
import { agentsActions } from "modules/gpo-agent/redux/agents";

import "./BlankSession.scss";

const BlankSession = () => {

  const dispatch = useDispatch();
  const acceptConversation = useSelector((state: RootState) => state.agents.acceptConversation.status);

  useEffect(() => {
    if (acceptConversation === ApiStatus.SUCCESS) {
      dispatch(agentsActions.getChatRequests());
    }
  }, [acceptConversation]);

  return (
    <>
      <div className="blank-session">
        <div className="blank-session__container">
          <div className="message">
            <img src={isolation} />
            <strong>Sit back and relax</strong>
            <p>You dont have any active session right now </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlankSession;
