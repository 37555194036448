import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatSession from "modules/gpo-agent/components/ChatSession/ChatSession";
import Header from "modules/gpo-agent/components/Header/Header";
import SideBar from "modules/gpo-agent/components/SideBar/SideBar";
import ChatHistory from "modules/gpo-agent/components/ChatHistory/ChatHistory";
import { TabType } from "common/enums/tab-types.enum";
import SkeletonLoader from "modules/gpo-agent/components/SkeletonLoader/SkeletonLoader";
import { RootState } from "common/redux/core/root.reducer";
import BlankSession from "modules/gpo-agent/components/BlankSession/BlankSession";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import { agentChatHistoryActions } from "modules/gpo-agent/redux/agent-chat-history";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { ApiStatus } from "common/enums";
import IncommingChat from "modules/gpo-agent/components/IncommingChat/IncommingChat";

import "./Home.scss";
import UserManagement from "modules/gpo-agent/components/UserManagement/UserManagement";
import { USER_ROLE_CODE } from "common/enums/user-role-code.enum";

const Home = () => {
  const dispatch = useDispatch();

  const chatRequest = useSelector((state: RootState) => state.agents.chatRequest);
  const webPubSubNegotiateDetails = useSelector((state: RootState) => state.agents.webPubSubNegotiateDetails.data);
  const selectedTab = useSelector((state: RootState) => state.header.tabType);
  const chatRequestSilent = useSelector((state: RootState) => state.agents.chatRequestSilent.data);
  const selectedConversation = useSelector((state: RootState) => state.agents.selectedConversation);
  const session = useSelector((state: RootState) => state.agents.session.data);

  useEffect(() => {
    dispatch(agentsActions.webPubSubNegotiate());
  }, []);

  useEffect(() => {
    if (selectedTab == TabType.CHAT_HISTORY && session?.userRoles?.includes(USER_ROLE_CODE.AGENT)) {
      dispatch(agentChatHistoryActions?.clearChatHistoryList());
      dispatch(agentChatHistoryActions?.clearSelectedChatHistory());
      dispatch(agentChatHistoryActions?.clearChatHistoryMessages());
      dispatch(agentChatHistoryActions.getChatHistoryList(""));
      dispatch(agentsActions.clearData());
    } 
    else if(selectedTab == TabType.ACTIVE_SESSION && session?.userRoles?.includes(USER_ROLE_CODE.AGENT)) {
      dispatch(agentsActions.getChatRequests());
    }
    else if(selectedTab == TabType.USER_MANAGEMENT) {
      console.log("inside User management")
    }
  }, [selectedTab]);


  useEffect(() => {
    if (webPubSubNegotiateDetails) {
      const webPubSub = new WebSocket(webPubSubNegotiateDetails?.connectionUri);
      webPubSub.onmessage = (event) => {
        dispatch(agentsActions.getSilentChatRequest());
      };
    }
  }, [webPubSubNegotiateDetails]);

  const tabChange = (tab: TabType) => {
    dispatch(appHeaderActions?.updateNavBarTabChangeState(true));
    dispatch(appHeaderActions?.updateTabType(tab));
  };

  return (
    <div className="agent-wrapper">
      <div className="agent-wrapper__container">
        <SideBar currentTab={selectedTab} onTabChange={tabChange} />
        <div className="contents">
          {selectedTab === TabType.ACTIVE_SESSION && (
            <>
              {(chatRequest?.status === ApiStatus.SUCCESS && chatRequest?.data && chatRequest?.data?.length <= 0) && (<BlankSession />)}
              {(chatRequest?.status === ApiStatus.SUCCESS && chatRequest?.data && chatRequest?.data?.length > 0) && (
                <>
                  <Header />
                  <ChatSession />
                </>
              )}
              { }
            </>
          )}
          {chatRequest?.status === ApiStatus.LOADING && (<SkeletonLoader />)}
          {selectedTab === TabType.CHAT_HISTORY && (
            <ChatHistory />
          )}
          {selectedTab === TabType.USER_MANAGEMENT && (
            <UserManagement />
          )}
        </div>
      </div>
      {chatRequestSilent && chatRequestSilent.length === 1 && !selectedConversation && <IncommingChat request={chatRequestSilent[0]} />}
    </div>
  );
};

export default Home;
