import { ApiResult } from "common/models";
import { AddUserRequest } from "common/models/user/add-user.model";
import { GraphClient } from "common/services/graph-client.service";
import { HttpClient } from "common/services/http-client.service";
import { ApproveRejectRequestModel } from "modules/gpo-agent/models/user-confirmation/user-confirmation.model";
import userManagementApiConfig from "./user-management.config";


export class UserManagementService extends HttpClient {
    public static async getAllGraphUsers(key:string): Promise<ApiResult<any>> {
        return await GraphClient.searchUsers(key);
    }
    public static async addNewUser(requestDetails:AddUserRequest): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(userManagementApiConfig.addNewUser(requestDetails));
    }
    public static async approveRejectUser(requestDetails:ApproveRejectRequestModel): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(userManagementApiConfig.approveRejectUser(requestDetails));
    }
}
