import { logo, tick_icon, unauthorized, } from "assets/media/images";
import { ENV_CONFIG } from "common/config/env.config";
import { REQUEST_ACCESS_TYPE } from "common/enums/request-access-type.enum";
import { REQUEST_ACCESS_ERRORS } from "common/enums/request-error-code.enum";
import { RequestAccessModel } from "common/models/request-access.model";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
import { useEffect, useState } from "react";
import "./Unauthorized.scss";

const Unauthorized = () => {
  const [showProgress,setShowProgress] = useState<boolean>(false);
  const [showRequestResponse,setShowRequestResponse] = useState<boolean>(false);
  const userStateDispatch = useAppDispatch();
  const userState = useAppSelector((state: RootState) => state.user);

  useEffect(()=>{
    if(userState?.requestAccessResponse){
      if(userState?.requestAccessResponse){
         setShowProgress(false);
         setShowRequestResponse(true);       
      }
    }
  },[userState?.requestAccessResponse])

  const handleRequestAccess = ()=>{
    const accessRequest:RequestAccessModel={
      requestAccessType: REQUEST_ACCESS_TYPE.AgentPortal
    }
     userStateDispatch(userActions?.requestAccess(accessRequest));
     setShowProgress(true);

  }  
  return (
    <>
       <div className="unauthorised">
        <div className="unauthorised__container">
          <div className="gpo-chat-header">
              <div className="gpo-logo">
              <img src={logo} />
                 <span> Capital Edge AnswerBot</span>
              </div>
          </div>
          <div className={showRequestResponse ? "unauthorized-body" : "unauthorized-body active"}>
            {/* Loader */}
            {showProgress &&
              <div className="access-loader-wrapper">
              <div className="access-loader-wrapper__container">
                <div className="access-loader">
                  <span className="dot dot_1"></span>
                  <span className="dot dot_2"></span>
                  <span className="dot dot_3"></span>
                  <span className="dot dot_4"></span>
                  <h4>Loading...</h4>
                </div>
              </div>
            </div>
            }
            {!showRequestResponse && !showProgress && (
              <div className="unauthorized-texts">
                <img src={unauthorized} className="unauthorised-bg" />
                <h3>You don’t have access to this application!</h3>
                <span onClick={(e) => handleRequestAccess()}>Request Access</span>
              </div>
            )} 
            {showRequestResponse && !showProgress && (
              <div className="access-confirm">
                <div className="items">
                  <span className="tick">
                  <img src={tick_icon} />
                  </span>
                  <h3>Thank you</h3>
                  <p>Your request has been received.</p>
                  <p>We will respond to you soon.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Unauthorized;

