import { ApiStatus } from "common/enums";
import { TabType } from "common/enums/tab-types.enum";


interface IAppHeaderStater {
    tabType : TabType,
    isNavBarTabChange:boolean
}

const initialAppHeaderState: IAppHeaderStater = {
    tabType : TabType.ACTIVE_SESSION,
    isNavBarTabChange:false
};

export { initialAppHeaderState };
