import { UserSigningStatus } from "common/enums/user.enum";
import { IGraphUser } from "common/models";
import { RequestAccessResponseModel } from "common/models/request-access.model";

export interface IUserState {
    id: string;
    name: string;
    emailId: string;
    avatar: string;
    isAuthenticated: boolean;
    isTokenBlacklisted: boolean;
    isSigned: UserSigningStatus,
    userAvatar: string;
    userGuid :  string;
    graphUsers: Array<IGraphUser>|undefined;
    requestAccessResponse : RequestAccessResponseModel|undefined;
}

const initialUserState: IUserState = {
    name: '',
    emailId: '',
    id: '',
    avatar: '',
    isAuthenticated: false,
    isTokenBlacklisted: false,
    isSigned:UserSigningStatus.SIGNING_IN,
    userAvatar: '',
    userGuid :  '',
    graphUsers:undefined,
    requestAccessResponse:undefined,
};

export { initialUserState };
