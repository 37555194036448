import React, { FC, useEffect, useState } from "react";
import { UserService } from "common/redux/store/user/user.service";
import { avatar } from "assets/media/images";

import "./ProfileImage.scss";

interface ProfileImagePropes {
    profileId: string;
}

const ProfileImage: FC<ProfileImagePropes> = ({
    profileId
}) => {

    const [profileImage, setProfileImage] = useState<string>(avatar);

    useEffect(() => {
        if(profileId)
            UserService.getUserAvatar(profileId).then(profileImage => profileImage && profileImage?.value && setProfileImage((URL.createObjectURL(profileImage?.value ))));
    }, [profileId]);
    return (
        <>
            <img src={profileImage} className="profile-pic"/>
        </>
    );
};

export default ProfileImage;