import React, { FC, useEffect } from "react";
import useAuthenticate from "common/hooks/useAuthenticate";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux/core/root.reducer";
import { UserSigningStatus } from "common/enums/user.enum";
import { ApiStatus } from "common/enums";
import Loader from "common/components/base/Loader/Loader";
import AuthorizedLayout from "./AuthorizedLayout/AuthorizedLayout";
import Unauthorized from "modules/gpo-agent/components/Unauthorized/Unauthorized";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { TabType } from "common/enums/tab-types.enum";

const Layout: FC = () => {
    useAuthenticate();
    const isSigned = useSelector((state: RootState) => state.user.isSigned);
    const sessionStatus = useSelector(
        (state: RootState) => state.agents.session.status
    );
    const userSessionState = useSelector(
        (state: RootState) => state.agents.session
    );
    const dispatch = useAppDispatch();
    useEffect(()=>{
       if(userSessionState?.data?.userRoles){
        if(userSessionState?.data?.userRoles?.length==1 && userSessionState?.data?.userRoles?.includes('Admin')){
            dispatch(appHeaderActions?.updateTabType(TabType.USER_MANAGEMENT));
        }
       }
    },[userSessionState?.data])
    
    return (
        <>
            {(sessionStatus === ApiStatus.LOADING || sessionStatus === ApiStatus.NONE) && (<Loader />)}
            {(isSigned === UserSigningStatus.SIGNING_IN || isSigned === UserSigningStatus.SIGNED) && sessionStatus === ApiStatus.SUCCESS ? (
                <AuthorizedLayout />
            ) : (sessionStatus === ApiStatus.ERROR && (<Unauthorized />))}
        </>
    );
}

export default Layout;