import React from "react";
import "./Loader.scss";
type loaderProps = {fullScreen ? : boolean;}

const Loader = ({fullScreen} : loaderProps) => {
  return (
    <div className={["loader-wrapper" , fullScreen ? "fullscreen" : ""].join(' ')}>
      <div className="loader-wrapper__container">
      <div className="loader"></div>
        <span>Loading...</span>
      </div>
    </div>
  );
};
export default Loader;
