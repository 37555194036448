import { Method } from 'axios';

export type API_METHOD =
    | 'get'
    | 'GET'
    | 'delete'
    | 'DELETE'
    | 'head'
    | 'HEAD'
    | 'options'
    | 'OPTIONS'
    | 'post'
    | 'POST'
    | 'put'
    | 'PUT'
    | 'patch'
    | 'PATCH'
    | 'purge'
    | 'PURGE'
    | 'link'
    | 'LINK'
    | 'unlink'
    | 'UNLINK';

export class ApiConfig<D> {
    url: string;
    method: API_METHOD;
    queryString?: string;
    data?: D;
    header?: { [key: string]: string };
    constructor(
        _url = '',
        _method: API_METHOD = 'GET',
        _queryString = '',
        _data?: D,
        _header = {}
    ) {
        this.url = _url;
        this.method = _method;
        this.data = _data;
        this.queryString = _queryString;
        this.header = _header;
    }
}

