import { avatar2, close_white, down, drop_down } from "assets/media/images";
import Loader from "common/components/base/Loader/Loader";
import TableLoader from "common/components/base/TableLoader/TableLoader";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { USER_ROLE_CODE } from "common/enums/user-role-code.enum";
import useDebounce from "common/hooks/useDebounce";
import { IGraphUser } from "common/models";
import { AddUserRequest } from "common/models/user/add-user.model";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userManagementActions } from "common/redux/store/user-management";
import { FC, useEffect, useRef, useState } from "react";
import "./AddUser.scss";

const DEBOUNCE_TIME_OUT = 800;
type AddUserProps = {
  onAddNewUser: (newUserAddedResponse:any) => void;
  onCloseModal: () => void;
};
const AddUser : FC<AddUserProps> = ({
  onAddNewUser,
  onCloseModal
}: AddUserProps) => {
  const [isUserDropdown, setIsUserDropdown] = useState(false);
  const userDropdown = (isUserDropdown) => {
    setIsUserDropdown((isUserDropdown) => !isUserDropdown);
  };
  const ref = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm:any = useDebounce(searchTerm, DEBOUNCE_TIME_OUT);
  const userManagementDispatch = useAppDispatch();
  const userManagementState = useAppSelector((state: RootState) => state.userManagement);
  const [graphUsers, setGraphUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState<IGraphUser>();
  const[addingUserInProgress, setAddingUserInProgress] = useState<boolean>(false);
  const [selectedUserRoleCodes, setSelectedUserRoleCodes] = useState<Array<USER_ROLE_CODE>>([]);
  const textBoxRef:any = useRef<HTMLInputElement>(null);
  const [roleSelected,setRoleSelected] = useState<boolean>(false);
  useEffect(()=>{
    if(userManagementState?.graphUsers){
      let response = userManagementState?.graphUsers.filter((user:any)=>user?.mail?.endsWith("ey.com"));
      response = response.slice(0,5);
      setGraphUsers(response);
    }
  },[userManagementState?.graphUsers])

  useEffect(()=>{
    if(userManagementState?.addUserResponse){
        setAddingUserInProgress(false);
        const status = userManagementState?.addUserResponse[0];
        onAddNewUser(status);
        userManagementDispatch(userManagementActions.clearaddNewUserResponse());
    }
  },[userManagementState?.addUserResponse])


  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isUserDropdown && ref.current && !ref.current.contains(e.target)) {
        setGraphUsers([]);
        setIsUserDropdown(false);
        if(!selectedUser){
          if(textBoxRef?.current){
            textBoxRef.current.value = "";
          }
        }
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isUserDropdown])
  
  useEffect(() => {
    setGraphUsers([]);
    if(debouncedSearchTerm){
      userManagementDispatch(userManagementActions.getAllGraphUsers(debouncedSearchTerm)); 
    }
    else{
      setSelectedUser(undefined);
    }
  }, [debouncedSearchTerm]);

  const handleSearchInputChange = (searchValue) => {
    if (searchValue) {
      setSearchTerm(searchValue);
    } else {
      setSearchTerm('');
    }
  }
  const selectUserToAdd = (selectedUser : IGraphUser) =>{
    setSelectedUser(selectedUser);
    if(textBoxRef?.current){
      textBoxRef.current.value = selectedUser?.displayName;
      setGraphUsers([]);
      setIsUserDropdown(false);
    }
  }
  const handleCheckBoxChange = (checkBoxValue , checkBoxId ) =>{
    let selectedUserRoles = selectedUserRoleCodes;
    if(checkBoxValue){
      selectedUserRoles.push(checkBoxId);
    }
    else{
      const index = selectedUserRoles.indexOf(checkBoxId);
       if (index > -1) { 
        selectedUserRoles.splice(index, 1);
      }
    }
    setSelectedUserRoleCodes(selectedUserRoles);
  }
  const handleAddNewUser = () =>{
    const newUseRequest : AddUserRequest ={
      users: [
        {
          email:selectedUser?selectedUser?.mail:'',
          roleCodes:selectedUserRoleCodes,
          isActive:true
        }
      ]
    }
    setAddingUserInProgress(true);
    userManagementDispatch(userManagementActions.addNewUsers(newUseRequest));
  }

  const checkUserSelection = () =>{
    if(selectedUser && selectedUserRoleCodes?.length){
      return false;
    }
    else{
      return true;
    }
  }
  const markFormDirty = (e) =>{
    if(e.target?.type=="checkbox"){
      const element = document.querySelector(".role__options");
      if(element){
        const checkedCheckBoxes = element?.querySelectorAll("input[type=checkbox]:checked");
        if(checkedCheckBoxes?.length){
           setRoleSelected(true);
        }
        else{
          setRoleSelected(false);
        }
      }
    }
  }
  return (
    <div className="add-user">
      {addingUserInProgress &&(
        <TableLoader/>
      )}
      <div className="add-user__container">
        <div className="wrapper">
          <header>
            <h3>Add User</h3>
            <img src={close_white} alt="Close" onClick={(e) => onCloseModal()}/>
          </header>
          <form onChange={(e) => markFormDirty(e)}>
          <div className="select-user" ref={ref}>
            <div
              className={
                !isUserDropdown ? "material-input" : "material-input active"
              }
              onClick={userDropdown}
            >
              <input type="text" ref={textBoxRef} required  onChange={(e) => handleSearchInputChange(e.target.value)}   />
              <label>Select user (required)</label>
            </div>
            {isUserDropdown && (
              <div className="options">
                {!graphUsers?.length &&(
                <span>No data found</span>
                )}
                {graphUsers && graphUsers?.map((user:IGraphUser, i) => 
                 <span key={user.id} onClick={(e) => selectUserToAdd(user)}>
                 <ProfileImage profileId={user.id}/> {user.displayName}
                 </span>
                )}
              </div>
            )}
          </div>
          <div className="role">
            <h5>Select role(s)</h5>
            <div className="role__options">
              <input type="checkbox" id="Client"  onChange={(e) => handleCheckBoxChange(e.target.checked,'Client')}/>
              <label htmlFor="Client">
                <span></span> User
              </label>

              <input type="checkbox" id="Agent"  onChange={(e) => handleCheckBoxChange(e.target.checked,'Agent')}/>
              <label htmlFor="Agent">
                <span></span>Knowledge worker
              </label>

              <input type="checkbox" id="Admin"  onChange={(e) => handleCheckBoxChange(e.target.checked,'Admin')}/>
              <label htmlFor="Admin">
                <span></span> Admin
              </label>

            </div>
          </div>
          </form>
          <footer>
            <button className="btn btn-primary" disabled={!(selectedUser && roleSelected) } onClick={(e) => handleAddNewUser()}>Add User</button>
            <button className="btn btn-secondary" onClick={(e) => onCloseModal()}>Cancel</button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
