import { close_white } from "assets/media/images";
import { FC, useEffect, useState } from "react";
import "./ApproveReject.scss";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import { userManagementActions } from "common/redux/store/user-management/user-management.slice";
import TableLoader from "common/components/base/TableLoader/TableLoader";

type ApproveRejectProps = {
  gridData:any;
  message:string;
  hasErrorBtn:boolean;
  onAction: (gridData:any,actionName:string) => void;
  onCloseModal: () => void;
  onActionCompleted: (completionStatus:boolean) => void;
};
const ApproveReject : FC<ApproveRejectProps> = ({
  gridData,
  message,
  hasErrorBtn,
  onAction,
  onCloseModal,
  onActionCompleted
}: ApproveRejectProps) => {
  const userManagementDispatch = useAppDispatch();
  const userManagementState = useAppSelector((state: RootState) => state.userManagement);
  const[actionInProgress, setActionInProgress] = useState<boolean>(false);

  useEffect(()=>{
    if(userManagementState?.userConfirmationRespone){
        setActionInProgress(false);
        const status = userManagementState?.userConfirmationRespone.isSuccess;
        onActionCompleted(status);
        userManagementDispatch(userManagementActions.clearApproveRejectUserResponse());
    }
  },[userManagementState?.userConfirmationRespone])

  const initiateAction = (actionName) =>{
    setActionInProgress(true);
    onAction(gridData,actionName)
  }
  return (
    <div className="approve-reject">
      {actionInProgress &&(
        <TableLoader/>
      )}
      <div className="approve-reject__container">
        <div className="wrapper">
          <header>
            <h3>Confirm</h3>
            <img src={close_white} alt="Close" onClick={(e) => onCloseModal()}/>
          </header>
          <p>{message}</p>
          <footer>
            {!hasErrorBtn && (
              <button className="btn btn-primary" onClick={(e) => initiateAction('approve')}>Approve</button>
            )}
            {hasErrorBtn && (
              <button className="btn btn-error" onClick={(e) => initiateAction('reject')}>Reject</button>
            )}
            
            <button className="btn btn-secondary" onClick={(e) => onCloseModal()}>Cancel</button>
          </footer>
        </div>
      </div>
    </div>
  );
};
export default ApproveReject;
