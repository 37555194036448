import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "common/redux/core/root.reducer";
import { UserSigningStatus } from "common/enums/user.enum";
import Logout from "Layout/Logout/Logout";
import MsalAuthProvider from "common/components/custom/MsalAuthProvider";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Layout from "Layout/Layout";

const App: FC = () => {
  const isSigned = useSelector((state: RootState) => state.user.isSigned);

  return (
    <>
      <MsalAuthProvider>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{
            scopes: ['User.Read']
          }}>
          <Layout />
        </MsalAuthenticationTemplate>
      </MsalAuthProvider>
      {isSigned === UserSigningStatus.SIGNED_OUT && (<Logout />)}
    </>
  );
};

export default App;
