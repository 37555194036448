import { CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { AddUserRequest } from "common/models/user/add-user.model";
import { ApproveRejectRequestModel } from "modules/gpo-agent/models/user-confirmation/user-confirmation.model";

const userManagementApiConfig = {   
    addNewUser: (requestDetails:AddUserRequest): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.USER}`, 'POST','', requestDetails),  
    approveRejectUser: (requestDetails:ApproveRejectRequestModel): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.ADMIN}/${METHOD.PROCESS_REQUEST}`, 'POST','', requestDetails),   
};

export default userManagementApiConfig;
