import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/enums';
import { IACSContext } from 'modules/gpo-agent/models/agents/acs-context.model';
import { HandOffStatusEnum, IChatRequest } from 'modules/gpo-agent/models/agents/chat-request.model';
import { ISession } from 'modules/gpo-agent/models/agents/session.model';
import { WebPubSubDetails } from 'modules/gpo-agent/models/agents/web-pub-sub-details.model';
import { initalAgentsState } from './agents.state';

const agentsSlice = createSlice({
    name: 'agents',
    initialState: initalAgentsState,
    reducers: {
        getSession: (state) => {
            state.session = {
                status: ApiStatus.LOADING
            };
        },
        getSessionSuccess: (state, { payload }: PayloadAction<ISession>) => {
            state.session = {
                status: ApiStatus.SUCCESS,
                data: payload
            };
        },
        getSessionFailed: (state, { payload }: PayloadAction<any>) => {
            state.session = {
                status: ApiStatus.ERROR,
                data: payload
            };
        },
        getChatRequests: (state) => {
            if (!state.chatRequest) {
                state.chatRequest = {};
            }
            state.chatRequest.status = ApiStatus.LOADING;
        },
        getChatRequestsSuccess: (state, { payload }: PayloadAction<IChatRequest[]>) => {
            if (!state.chatRequest) {
                state.chatRequest = {};
            }
            state.chatRequest.status = ApiStatus.SUCCESS;
            state.chatRequest.data = payload;
            state.chatRequestSilent = {
                status: ApiStatus.SUCCESS,
                data: payload
            };
            if (payload && payload?.length > 0)
                state.selectedConversation = payload?.find(item => item?.handOffStatusId === HandOffStatusEnum.Accepted);
            else
                delete state.selectedConversation;
            state.acceptConversation = {
                status: ApiStatus.NONE,
                data: false
            };
            state.rejectConversation = {
                status: ApiStatus.NONE,
                data: false
            };
        },
        getChatRequestsFailed: (state, { payload }: PayloadAction<any>) => {
            if (!state.chatRequest) {
                state.chatRequest = {};
            }
            state.chatRequest.status = ApiStatus.ERROR;
            state.chatRequest.error = payload;
        },
        acceptConversation: (state, { payload }: PayloadAction<string>) => {
            if (!state.acceptConversation) {
                state.acceptConversation = {};
            }
            state.acceptConversation.status = ApiStatus.LOADING;
        },
        acceptConversationSuccess: (state, { payload }: PayloadAction<any>) => {
            if (!state.acceptConversation) {
                state.acceptConversation = {};
            }
            state.acceptConversation.status = ApiStatus.SUCCESS;
            state.acceptConversation.data = true
        },
        acceptConversationFailed: (state, { payload }: PayloadAction<any>) => {
            if (!state.acceptConversation) {
                state.acceptConversation = {};
            }
            state.acceptConversation.status = ApiStatus.ERROR;
            state.acceptConversation.error = payload;
        },
        getACSContext: (state) => {
            state.aCSContext = {
                status: ApiStatus.LOADING
            };
        },
        getACSContextFailed: (state, { payload }: PayloadAction<any>) => {
            state.aCSContext = {
                status: ApiStatus.ERROR,
                error: payload
            };
        },
        getACSContextSuccess: (state, { payload }: PayloadAction<IACSContext>) => {
            state.aCSContext = {
                status: ApiStatus.SUCCESS,
                data: payload
            };
        },
        clearData: (state) => {
            state.chatRequest = {};
            state.acceptConversation = {};
            state.aCSContext = {};
            state.leaveConversation = {};
            state.rejectConversation = {};
            state.chatRequestSilent = {}
        },
        leaveConversation: (state, { payload }: PayloadAction<string>) => {
            state.leaveConversation = {
                status: ApiStatus.LOADING,
            };
        },
        leaveConversationSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.leaveConversation = {
                status: ApiStatus.SUCCESS,
                data: payload
            };
            state.aCSContext = {};
        },
        leaveConversationFailed: (state, { payload }: PayloadAction<any>) => {
            state.leaveConversation = {
                status: ApiStatus.ERROR,
                error: payload
            };
        },
        getSilentChatRequest: (state) => {
            state.chatRequestSilent = {
                status: ApiStatus.LOADING,
            };

            state.leaveConversation = {};
            state.rejectConversation = {};
        },
        getSilentChatRequestSuccess: (state, { payload }: PayloadAction<IChatRequest[]>) => {
            state.chatRequestSilent = {
                status: ApiStatus.SUCCESS,
                data: payload
            };
            if (payload && payload?.length > 0 && !state?.selectedConversation?.handOffStatusId)
                state.selectedConversation = payload?.find(item => item?.handOffStatusId === HandOffStatusEnum.Accepted);
            else if (!payload || payload?.length <= 0)
                delete state.selectedConversation;
            state.acceptConversation = {
                status: ApiStatus.NONE,
                data: false
            };
            state.rejectConversation = {
                status: ApiStatus.NONE,
                data: false
            };
        },
        getSilentChatRequestFailed: (state, { payload }: PayloadAction<any>) => {
            state.chatRequestSilent = {
                status: ApiStatus.ERROR,
                error: payload
            };
        },
        rejectConversation: (state, { payload }: PayloadAction<string>) => {
            state.rejectConversation = {
                status: ApiStatus.LOADING
            };
        },
        rejectConversationSuccess: (state, { payload }: PayloadAction<any>) => {
            state.rejectConversation = {
                status: ApiStatus.SUCCESS,
                data: true
            };
        },
        rejectConversationFailed: (state, { payload }: PayloadAction<any>) => {
            state.rejectConversation = {
                status: ApiStatus.ERROR,
                error: payload
            };
        },

        webPubSubNegotiate: (state) => {
            state.webPubSubNegotiateDetails = {
                status: ApiStatus.LOADING
            };
        },
        webPubSubNegotiateSuccess: (state, { payload }: PayloadAction<WebPubSubDetails>) => {
            state.webPubSubNegotiateDetails = {
                status: ApiStatus.SUCCESS,
                data: payload
            };
        },
        webPubSubNegotiateFailed: (state, { payload }: PayloadAction<any>) => {
            state.webPubSubNegotiateDetails = {
                status: ApiStatus.ERROR,
                error: payload
            };
        },
        setDataToChatRequest: (state) => {
            state.chatRequest = state.chatRequestSilent;
            state.selectedConversation = state.chatRequest?.data?.find(item => item?.handOffStatusId === HandOffStatusEnum.Accepted);
        }
    }
});

export const agentsActions = agentsSlice.actions;

export default agentsSlice.reducer;
