export const CONTROLLER = {
    AGENTS:'Agents',
    WEB_PUB_SUB:'WebPubSub',
    USER:'User',
    ADMIN:'Admin'
};

export const METHOD = {
    SESSION:'session',
    CHAT_HISTORY:'chat-history',
    CHAT:'chat',
    MESSAGES:'messages',
    CHAT_REQUESTS:'chat-requests',
    ACCEPT: 'accept',
    CONTEXT: 'context',
    DOCUMENTS:'documents',
    COMPLETE_ANNOTATION : 'complete-annotation',
    LEAVE: 'leave',
    NEGOTIATE: "negotiate",
    ANNOTATE:'annotate',
    PROCESS_REQUEST:'process-request',
    REQUEST_ACCESS:'request-access'
};

export const QUERY_PARAMS = {

};

export const WEB_PUB_SUB_CONFIG ={
    HUB_NAME: 'notify'
}

