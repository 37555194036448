import { CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { DocumentTypes } from "modules/gpo-agent/enums/document-types.enum";
import { AnnotateMessageRequest } from "modules/gpo-agent/models/chat-history/chat-history-annotate-message.model";

const agentsChatHistoryApiConfig = {
    
    chatHistory: (searchText?:string): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT_HISTORY}`, 'GET',`&searchText=${searchText?searchText:""}`),
    
    chatHistoryMessages: (handoffGuid:string): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT}/${handoffGuid}/${METHOD.MESSAGES}`, 'GET'),  
    
    referenceDocument: (documentType:DocumentTypes): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.DOCUMENTS}`, 'GET',`documentTypeEnum=${documentType}`), 

    completeAnnotation: (handoffGuid:string): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT}/${handoffGuid}/${METHOD.COMPLETE_ANNOTATION}`, 'POST','', {},{'handoffGuid':handoffGuid}),  

    annotateMessage: (requestDetails:AnnotateMessageRequest): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT}/${requestDetails?.handoffGuid}/${METHOD.MESSAGES}/${requestDetails?.messageGuid}/${METHOD.ANNOTATE}`, 'POST','', requestDetails?.annotateMessageDetails),      
};

export default agentsChatHistoryApiConfig;
