import { AgGridReact } from "ag-grid-react";
import { edit_icon } from "assets/media";
import Loader from "common/components/base/Loader/Loader";
import TableLoader from "common/components/base/TableLoader/TableLoader";
import { GridSortModel } from "common/models/grid-sort-model";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { GridService } from "common/services/table-service";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ActionCellRenderer from "../ActionCellRenderer/ActionCellRenderer";
import ArrayofDataCellRenderer from "../ArrayOfDataCellRenderer/ArrayofDataCellRenderer";
import AvatarCellRenderer from "../AvatarCellRenderer/AvatarCellRenderer";
import StatusCellRenderer from "../StatusCellRenderer/StatusCellRenderer";


import './GPOAgGrid.scss';

interface GPOAgGridProps {
    url:string;
    paginationSize:number;
    columnDefinitions:any;
    searchFilter?:string|undefined;
    refreshGrid?:boolean;
    additionalQuery?:string|undefined;
    initialSortModel?:GridSortModel;
  }
  
  const GPOAgGrid: FC<GPOAgGridProps> = ({
    url,
    paginationSize,
    columnDefinitions,
    searchFilter=undefined,
    refreshGrid,
    additionalQuery=undefined,
    initialSortModel
  }) => {
    const userManagementDispatch = useAppDispatch();
    const userManagementState = useAppSelector((state: RootState) => state.userManagement);
    const [searchText,setSearchText] = useState("");
    const [index,setIndex] = useState(1);

    const [gridActivated,setGridActivated] = useState(true);
    const gridRef:any = useRef();
    useEffect(()=>{
      if(searchFilter!=undefined){
        setSearchText(searchFilter);
        recreateGrid();
      }
    },[searchFilter])

    useEffect(()=>{
      if(additionalQuery!=undefined){
        recreateGrid();
      }
    },[additionalQuery])

    useEffect(()=>{
      if(refreshGrid){
        recreateGrid();
      }
    },[refreshGrid])


    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);


    const recreateGrid = () =>{
      setGridActivated(false);
        const timeoutHandler = setTimeout(() => {
          setGridActivated(true);
        }, 10);
         return () => {
          clearTimeout(timeoutHandler);
        };
    }
    const refreshCache = () => {
      //gridRef?.current?.api?.refreshServerSide({ route: route, purge: true });
      gridRef?.current?.api?.refreshInfiniteCache();
    };

    const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        minWidth: 100,
      };
    }, []);
    const [gridApi, setGridApi] = useState<null | {setDatasource: any,showLoadingOverlay:any,hideOverlay:any,showNoRowsOverlay:any}>(null);
    const [gridColumnApi, setGridColumnApi] = useState<null | {applyColumnState:any,getColumn:any}>(null);
    const perPage = 10;

    const onGridReady = (params) => {
     setGridApi(params.api);
     setGridColumnApi(params.columnApi);
    };
    useEffect(()=>{
      if(initialSortModel && gridColumnApi){
        const columnState = { 
          state: [
            initialSortModel
          ]
        }
        gridColumnApi.applyColumnState(columnState);
        
        
        //gridColumnApi.getColumn(initialSortModel.colId).setSort(initialSortModel.sort)
      }
    },[initialSortModel,gridColumnApi])
    useEffect(() => {
        if (gridApi) {
          const dataSource = {
            getRows: (params) => {
              // Use startRow and endRow for sending pagination to Backend
              // params.startRow : Start Page
              // params.endRow : End Page
              gridApi!.showLoadingOverlay();
              const pageNumber: number = Math.round((params.endRow) / paginationSize);
              //fetch(`https://reqres.in/api/users?per_page=${perPage}&page=${page}`)
                //fetch('https://usedgpowap04.azurewebsites.net/api/User')
                //GridService.getTableData('User')
                let sortOrder;
                let sortColumn;
                if (initialSortModel) {
                   sortOrder = initialSortModel.sort == 'asc' ? 1 : 2;
                   sortColumn = initialSortModel.colId;
               }
                if (params.sortModel.length) {
                  sortOrder = params.sortModel[0].sort == 'asc' ? 1 : 2;
                  sortColumn = params.sortModel[0].colId;
                }
        
                let filterParamString = "";
                // this.filterChanges.forEach((filterChange: TablefilterInput) => {
                //   if (filterChange) {
                //     let filterName = filterChange.filterName;
                //     let filterValues = filterChange.filterValues;
                //     if (this.activeComponentInfo?.customFilters.length && filterName && filterValues.length) {
                //       let requiredFilter: TableCustomFilters[] = this.activeComponentInfo?.customFilters.filter((filter: TableCustomFilters) => filter.name == filterName);
        
                //       let requiredFilterName = "";
                //       let requiredQueryParams: any = [];
                //       if (requiredFilter) {
                //         requiredFilterName = requiredFilter[0].name;
                //         requiredQueryParams = requiredFilter[0].queryParams;
                //       }
        
                //       for (var i = 0; i < requiredQueryParams.length; i++) {
                //         filterParamString = filterParamString + `&${requiredQueryParams[i]}=${filterValues[i]}`;
                //       }
                //     }
                //   }
                // });
        
                let searchFilterParam:any = "";
                let additionalQueryParam:any=undefined;
                if (searchText != "") {
                  searchFilterParam = searchText;
                }   
                if(additionalQuery){
                  additionalQueryParam = additionalQuery;
                }       
                GridService.getServerSidePaginationData(url,pageNumber,paginationSize,sortOrder,sortColumn,filterParamString,searchFilterParam,additionalQueryParam)
                .then((data:any) => {
                    if(data?.value?.items?.length > 0){
                       gridApi!.hideOverlay();
                       params.successCallback(data?.value?.items,data?.value?.totalResults);
                    //    if(!data?.value?.items?.length){
                    //     this.gridApi.showNoRowsOverlay();
                    //    }
                    //  if(this.activeComponentInfo?.isServerSidePagination){
                    //    this.setTypeAheadValues(data?.value?.items);
                    //     if(this.refreshAfter){
                    //     this.gridApi.paginationProxy.goToPage(this.currentPageNumber-1);
                    //     this.refreshAfter=false;
                    //     }
                    //   }
                      } else {
                        if(!data?.value?.items?.length){
                          gridApi!.showNoRowsOverlay();
                          //alert("No data");
                        }
                      }
                    })
                    .catch((err: any) => {
                      gridApi!.showNoRowsOverlay();
                      params.successCallback([], 0);
                      console.error(err);
                    });
            }
          }
    
          gridApi!.setDatasource(dataSource);
        }
      }, [gridApi]);
    return(
        <div style={containerStyle}>
        <div className="ag-theme-alpine ag-theme-alpine-dark">
          {gridActivated &&(
          <AgGridReact
          key={index}
          ref={gridRef}
          pagination={true}
          rowModelType={'infinite'}
          paginationPageSize={perPage}
          cacheBlockSize={perPage}
          onGridReady={onGridReady}
          rowHeight={60}
          defaultColDef={{ flex: 1 }}
          columnDefs={columnDefinitions}
          loadingOverlayComponent={TableLoader}
          overlayNoRowsTemplate={
            '<span style={{color: "red"}}>No data found to display.</span>'
          }

        >
        </AgGridReact>

          )}
        </div>
      </div>
    )   
  }

  export default GPOAgGrid;