import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGraphUser } from 'common/models';
import { AddUserRequest, AddUserResponse } from 'common/models/user/add-user.model';
import { ApproveRejectRequestModel, ApproveRejectResponseModel } from 'modules/gpo-agent/models/user-confirmation/user-confirmation.model';
import { initialUserManagementState } from './user-management.state';

const userManagementSlice = createSlice({
    name: 'usermanagement',
    initialState: initialUserManagementState,
    reducers: {
        updateSearchTerm: (state, { payload }: PayloadAction<any>) => {
            state.searchTerm = payload;
        },
        getAllGraphUsers:(state, { payload }: PayloadAction<string>) => {
            
        },
        getAllGraphUsersSuccess: (state, { payload }: PayloadAction<any>) => {
            state.graphUsers = payload;
        },
        getAllGraphUsersFailed: (state, { payload }: PayloadAction<any>) => {
            state.graphUsers = undefined;
            console.log("Errors",payload);
        },
        addNewUsers:(state, { payload }: PayloadAction<AddUserRequest>) => {
            
        },
        addNewUsersSuccess: (state, { payload }: PayloadAction<Array<AddUserResponse>>) => {
            state.addUserResponse = payload;
        },
        addNewUsersFailed: (state, { payload }: PayloadAction<any>) => {
            state.addUserResponse = undefined;
            console.log("Errors",payload);
        },
        clearaddNewUserResponse :(state) =>{
            state.addUserResponse = undefined;
        },
        approveRejectUser:(state, { payload }: PayloadAction<ApproveRejectRequestModel>) => {
            
        },
        approveRejectUserSuccess: (state, { payload }: PayloadAction<ApproveRejectResponseModel>) => {
            state.userConfirmationRespone = payload;
        },
        approveRejectUserFailed: (state, { payload }: PayloadAction<any>) => {
            state.userConfirmationRespone = undefined;
            console.log("Errors",payload);
        },
        clearApproveRejectUserResponse :(state) =>{
            state.userConfirmationRespone = undefined;
        },
        
    }
});

export const userManagementActions = userManagementSlice.actions;

export default userManagementSlice.reducer;
