import { IGraphUser } from "common/models";
import { AddUserRequest, AddUserResponse } from "common/models/user/add-user.model";
import { ApproveRejectResponseModel } from "modules/gpo-agent/models/user-confirmation/user-confirmation.model";

export interface IUserManagementState {
   searchTerm:string|undefined;
   graphUsers: any;
   addUserResponse:Array<AddUserResponse>|undefined;
   updateUserRequest:AddUserRequest|undefined;
   userConfirmationRespone:ApproveRejectResponseModel|undefined;
}

const initialUserManagementState: IUserManagementState = {
   searchTerm:undefined,
   graphUsers:undefined,
   addUserResponse:undefined,
   updateUserRequest:undefined,
   userConfirmationRespone:undefined
};

export { initialUserManagementState };
