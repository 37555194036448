import { close_white, error, success } from "assets/media/images";
import { FC, useEffect } from "react";
import "./Toaster.scss";

type ToasterProps = {
  message: string;
  isSuccess: boolean;
  closeTime: number;
  onCloseToast: () => void;
};
const Toaster: FC<ToasterProps> = ({
  message,
  isSuccess,
  closeTime,
  onCloseToast,
}: ToasterProps) => {
  useEffect(() => {
    setTimeout(() => {
      onCloseToast();
    }, closeTime);
  }, []);
  return (
    <div className={isSuccess ? "toast toast--success" : "toast toast--error"}>
      <div className="toast__body">
        {isSuccess ? <img src={success} /> : <img src={error} />}
        <p className="msg">{message}</p>
      </div>
      <div className="toast__close">
        <img src={close_white} />
      </div>
    </div>
  );
};
export default Toaster;
