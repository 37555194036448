
export enum HandOffStatusEnum {
    None = 0,
    Queued = 1,
    Accepted = 2,
    Closed = 3
}

export interface IChatRequest {
    conversationGuid: string;
    conversationReferenceId: string;
    handOffGuid: string;
    handOffStatusId: HandOffStatusEnum;
    handOffStartDate: any;
    userGuid: string;
    emailId: string;
    displayName: string;
    conversationThreadId: string;
    canAccept : boolean;
}